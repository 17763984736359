import { useBlogQuery, useRecipeTagsQuery } from "@api";
import { Container, P } from "@util/standard";
import styled from "styled-components";

import * as React from "react";
import { navigate } from "gatsby";
import { assets, colors } from "@util/constants";
import {
  Maybe,
  SanityBlogCategory,
  SanityBlogCategoryConnection,
  SanityRecipeTagConnection,
} from "@graphql-types";

interface Props {
  isRecipes?: boolean;
  isBlogs?: boolean;
  selectedCategory?: Maybe<string> | undefined;
  selectedBlogCategory?: Maybe<string> | undefined;
  isAll?: boolean;
  allSanityBlogCategory?: SanityBlogCategoryConnection;
  allSanityRecipeTag?: SanityRecipeTagConnection;
}

const StyledTitleContainer = styled(Container)<{
  isOpen?: boolean;
  isCurrent?: boolean;
}>`
  border-bottom: 1px solid;
  transition: 0.3s all ease;
  ${({ isCurrent }) =>
    isCurrent &&
    `
    pointer-events: none;
    color: ${colors.anathothRed};
  `}
  &:hover {
    opacity: 0.5;
  }
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const CategoryMenu = (props: Props) => {
  const {
    isRecipes,
    selectedCategory,
    isAll,
    isBlogs,
    selectedBlogCategory,
    allSanityBlogCategory,
    allSanityRecipeTag,
  } = props;

  if (allSanityRecipeTag == null && allSanityBlogCategory == null) {
    return null;
  }

  return (
    <>
      {isRecipes && (
        <Container width="230px" display="flex" flexDirection="column">
          {allSanityRecipeTag &&
            allSanityRecipeTag.nodes.map((category, i) => {
              if (category?.main == null) return;

              const isCurrent =
                selectedCategory === category.main.slug?.current;
              return (
                <StyledTitleContainer
                  width="100%"
                  tabletWidth="92.5%"
                  margin="0px auto"
                  key={i}
                  isCurrent={isCurrent}
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/recipes/${category.main?.slug?.current}`)
                  }
                >
                  <Container
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    <P fontSize={15} bold={isCurrent}>
                      {category.main.title}
                    </P>
                    <img
                      src={assets.arrowRight}
                      alt="Arrow Right"
                      width="8"
                      height="16"
                    />
                  </Container>
                </StyledTitleContainer>
              );
            })}
          <StyledTitleContainer
            width="230px"
            tabletWidth="92.5%"
            margin="0px auto"
            cursor="pointer"
            onClick={() => navigate(`/recipes-all`)}
          >
            <Container
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <P fontSize={15} bold={isAll ? true : false}>
                All Recipes
              </P>
              <img
                src={assets.arrowRight}
                alt="Arrow Right"
                width="8"
                height="16"
              />
            </Container>
          </StyledTitleContainer>
        </Container>
      )}

      {/* Blogs */}
      {isBlogs && (
        <Container width="230px" display="flex" flexDirection="column">
          {allSanityBlogCategory &&
            allSanityBlogCategory.nodes.map(category => {
              if (category?.main == null) return;

              const isCurrent =
                selectedBlogCategory === category.main.slug?.current;

              return (
                <StyledTitleContainer
                  width="100%"
                  tabletWidth="92.5%"
                  margin="0px auto"
                  key={category.main.title}
                  isCurrent={isCurrent}
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/blog/${category.main?.slug?.current}`)
                  }
                >
                  <Container
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    <P fontSize={15} bold={isCurrent}>
                      {category.main.title}
                    </P>
                    <img
                      src={assets.arrowRight}
                      alt="Arrow Right"
                      width="8"
                      height="16"
                    />
                  </Container>
                </StyledTitleContainer>
              );
            })}
          <StyledTitleContainer
            width="230px"
            tabletWidth="92.5%"
            margin="0px auto"
            cursor="pointer"
            isCurrent={isAll}
            onClick={() => navigate(`/blogs`)}
          >
            <Container
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <P fontSize={15} bold={isAll ? true : false}>
                All Blogs
              </P>
              <img
                src={assets.arrowRight}
                alt="Arrow Right"
                width="8"
                height="16"
              />
            </Container>
          </StyledTitleContainer>
        </Container>
      )}
    </>
  );
};

export default CategoryMenu;
